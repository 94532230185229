import React from "react";

function CompletedForm (){

    return(
        <div>
            <h1>COMPLETED FORM</h1>
        </div>
    )
}

export default CompletedForm  